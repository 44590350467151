.video_container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    margin-bottom: 1rem;
    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}