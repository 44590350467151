@font-face {
  font-family: 'Barlow';
  font-weight: normal;
  font-style : regular;
 // src: url('../public/files/project/fonts/Barlow-Regular.woff') format('woff'),
  //     url('../public/files/project/fonts/Barlow-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Barlow';
  font-weight: bold;
  font-style : regular;
  src: url('../public/files/project/fonts/Barlow-SemiBold.woff') format('woff'),
      url('../public/files/project/fonts/Barlow-SemiBold.ttf') format('ttf');
}

@font-face {
  font-family: 'project';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/project.woff') format('woff'),
      url('../public/files/project/fonts/project.ttf') format('ttf');
}
@font-face {
  font-family: 'AlumniSans';
  font-weight: normal;
  font-style : italic;
 src: url('../public/files/project/fonts/AlumniSans-MediumItalic.woff') format('woff'),
   url('../public/files/project/fonts/AlumniSans-MediumItalic.ttf')  format('ttf');
}
.title-font,
.content-font {
  font-family: 'Barlow';
}

.app-toolbar .content-font, .home-tile {
  font-family: 'Barlow';
}

.icon-font {
  font-family: 'project';
}

input, .generic-input, ::-webkit-input-placeholder {
  @extend .content-font;
}