
@import "../../../app-customs/config-scss/variables.scss";


[id=home-page] p {
    font-weight: bold;

    ons-button {
        margin: 2px 0 0 15px;
        cursor: pointer;
    }
}

// Onsenui hack
[id=home-page] .page__background {
    display: none;
}

[id=home-content] {
    display       : flex;
    flex-direction: column;
    height        : 100%;
   // padding       : 5px 3px 0 3px;
    background-color: $homeBackgroundColor;

    .is-ios & {
        position: fixed;
        width   : 100%
    }
    .is-ios .page-container.left & {
        width: $leftColumnsWidth;
    }
    .is-ios .page-container.right & {
        width: $rightColumnsWidth;
    }
}
