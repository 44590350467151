
@import "../../../app-customs/config-scss/variables.scss";
@import "../../scss/tile-click-effect.scss";


/* container */
.home-tiles {
    flex-grow: 1;
    overflow : auto;
    -webkit-overflow-scrolling: touch;
 //   margin   : 1.5em 0 0;
    color    : $tileTextColor;
}

// Reduce margin top for small devices
@media screen and (max-height: 500px) {
    .home-tiles {
        margin-top: 5%;
    }
}


/* tile */
.home-tile {
    display: flex;
    cursor : pointer;
    -webkit-tap-highlight-color: transparent;
}


/* apply margin to a sub-container which allows
   to ignore them while calculating widths and heights */
.home-tile-subcontainer {
    display    : flex;
    // align-items: center;
    margin     : 1em .3em 0;
    padding    : .2em .3em .4em;
    flex-grow  : 1;
    overflow   : hidden;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;

    @include tile-click-effect();
}


/* Tile legend (container of label+icon) */
.home-tile-legend {
    display       : flex;
    width         : 100%;
    height        : initial;
    text-align    : right;
    flex-direction: column;
    border-radius : 2px;

    & > * {
        //padding: 3px 1px;
    }
}

.centered-labels .home-tile-legend {
    text-align: center;
}


/* label background color*/
.home-tile-label-background-dark .home-tile-legend,
.home-tile-label-background-light .home-tile-legend {
    height: initial;
}
.home-tile-label-background-dark .home-tile-legend {
    background-color: rgba(0, 0, 0, .3);
}
.home-tile-label-background-light .home-tile-legend {
    background-color: rgba(255, 255, 255, .4);
}


.home-tile-legend-label {
    margin     : 0 .3em;
    font-size  : 1em;
    line-height: 1em;
}

.home-tile-legend-icon {
    font-size: 0;
    height   : $tileIconSize;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center center;

    &.icon-font {
        font-size: 2.2em;
    }
}

// Raise icon height from wide devices
/*@media screen and (min-width: 420px) {
    .home-tile-legend-icon {
        height: $tileIconSize + 20px;
    }
}*/


// Vertical spread for tiny tiles
.home-tile-width1 {

    .home-tile-legend-icon {
       // line-height: 0.5em;
        // padding-top: .4em;
    }
}
